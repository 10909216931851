<template>
  <v-container class="side-tutorial" fluid>
    <h1>How to create .side files for browser automation with Selenium IDE</h1>
    <p>
      Files with .side extension are produced by <strong>Selenium IDE</strong>,
      a browser extension used to record user input on a web page. You can
      download the extension for:
    </p>
    <p>
      Chrome:
      <a
        href="https://chrome.google.com/webstore/detail/selenium-ide/mooikfkahbdckldjjndioackbalphokd"
      >
        https://chrome.google.com/webstore/detail/selenium-ide/mooikfkahbdckldjjndioackbalphokd
      </a>
      <br />
      Mozilla Firefox:
      <a href="https://addons.mozilla.org/en-US/firefox/addon/selenium-ide/">
        https://addons.mozilla.org/en-US/firefox/addon/selenium-ide/
      </a>
    </p>
    <h1>Step by step guide</h1>
    <a class="step-link" href="#step1">Step 1: Install the extension</a>
    <a class="step-link" href="#step2">Step 2: Create new project</a>
    <a class="step-link" href="#step3">Step 3: Record your actions</a>
    <a class="step-link" href="#step4">
      Step 4: Import the .side file into your bot
    </a>
    <section id="step1">
      <h2>Step 1: Install the extension</h2>
      <p>
        Open one of the links above or visit Extensions store for your browser
        and install <strong>Selenium IDE</strong>.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome1.png" alt="" />
      <img src="@/assets/images/selenium-ide-tutorial/chrome2.png" alt="" />
      <p>
        <strong>Selenium IDE</strong> will now appear in your extensions panel.
        Click on it to open the IDE
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome3.png" alt="" />
    </section>
    <section id="step2">
      <h2>Step 2: Create new project</h2>
      <p>
        After that you are greeted with the welcome screen. Click "Record a new
        test in a new project".
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome4.png" alt="" />
      <p>Add a name for your new project and click OK.</p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome5.png" alt="" />
      <p>
        Paste a link to the web site you would like to record actions on and
        click Start Recording.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome6.png" alt="" />
    </section>
    <section id="step3">
      <h2>Step 3: Record your actions</h2>
      <p>
        After clicking the "Start Recording" button a new browser window with
        the website will open.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome7.png" alt="" />
      <p>
        You can now interact with the page, Selenium will record your actions
        and display them as a list of Commands.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome8.png" alt="" />
      <p>
        Once you finish recording your actions, bring up the Selenium window and
        click on Stop Recording
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome9.png" alt="" />
      <p>
        This will open a pop up window. Enter a name for your recording and
        click OK
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome10.png" alt="" />
      <p>
        At this point you can edit and play your recording. Once you finish
        editing your project, click Save.<br />This will start downloading your
        .side file.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome11.png" alt="" />
      <img src="@/assets/images/selenium-ide-tutorial/chrome12.png" alt="" />
      <img src="@/assets/images/selenium-ide-tutorial/chrome13.png" alt="" />
    </section>
    <section id="step4">
      <h2>Step 4: Import the .side file into your bot</h2>
      <p>
        After you downloaded your .side file, you can now go back to your bot
        and click Import .side file.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome14.png" alt="" />
      <img src="@/assets/images/selenium-ide-tutorial/chrome15.png" alt="" />
      <p>
        And that's it! You can now add new events, edit or delete them or
        reorder them by dragging the cards.
      </p>
      <img src="@/assets/images/selenium-ide-tutorial/chrome16.png" alt="" />
    </section>
  </v-container>
</template>

<script>
export default {
  name: "BrowserAutomationTutorial",
};
</script>

<style scoped lang="scss">
.side-tutorial {
  padding: 30px;
  .step-link {
    display: block;
    margin: 10px 0;
    color: black;
  }
  img {
    display: block;
    max-width: 700px;
    border: 1px solid grey;
    margin: 20px 0;
  }
}
</style>